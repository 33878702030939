import {Accordion, Button, Card} from "react-bootstrap";
import React, {useEffect, useState} from "react";
import {Amplify} from "aws-amplify";
import {Table} from "@amzn/awsui-components-react";
import {Link} from "react-router-dom";
import Icon from "@mdi/react";
import {mdiEye, mdiPencil} from "@mdi/js";
import {Tooltip} from "@material-ui/core";

/**
 * Checkpoint card with details that is displayed
 * @param props properties needed to render the checkpoint as follows
 *  - user: the user that is logged in containing alias
 *  - checkpoint: the checkpoint to render
 *  - index: the card number (used in the heading display)
 *  - process: the process that the checkpoint is in
 *  - responsibilityAttributeId: needed to deal with overrides, contains the attribute id for responsibility
 *  - attributes: list of all attributes
 *  - activeCheckpoint: the current selected checkpoint
 *  - handleActiveCheckpointChange: method to handle change in selection
 * @returns {JSX.Element|null}
 * @constructor
 */
export default function CheckpointCard(props) {
    const user = props.user;
    const checkpointId = props.checkpointId;
    const [checkpoint, setCheckpoint] = useState(null);
    const index = props.index;
    const process = props.process;
    const attributes = props.attributes;
    const activeCheckpoint = props.activeCheckpoint;
    const handleActiveCheckpointChange = props.handleActiveCheckpointChange;

    // criteria for checkpoint and list of assignees and delegates
    const [checkpointCriteria, setCheckpointCriteria] = useState({});

    const [tableValues, setTableValues] = useState([]);

    // method to pull checkpoint if selected
    useEffect(() => {
        if (activeCheckpoint === index && (!checkpoint || (checkpoint && checkpoint.checkpointId !== checkpointId))) {
            Amplify.API.get("ApproverMatrixAPI", `/checkpoints/${checkpointId}`).then(
                response => {
                    setCheckpoint(response)
                }
            )
        }
    }, [activeCheckpoint, index, checkpoint, checkpointId])


    // method to populate the criteria to be user friendly whenever criteria or keys are changed
    useEffect(() => {
        if (checkpoint) {
            let fieldTableValues = [
                {
                    field: "Checkpoint owner",
                    value: checkpoint.checkpointOwner
                },
                {
                    field: "Program Name",
                    value: checkpoint.programName
                },
                {
                    field: "Date",
                    value: checkpoint.checkpointDate
                }];

            Object.keys(checkpointCriteria).forEach(attributeName => {
                fieldTableValues.push({field: attributeName, value: checkpointCriteria[attributeName]});
            });

            setTableValues(fieldTableValues);
        }
    }, [checkpointCriteria, checkpoint])

    // method that sets keys and criteria for given checkpoint
    // we do not want dependencies on state variables so ignoring the use effect dependency warning
    useEffect(() => {
        if (checkpoint && attributes) {
            // loop through the criteria and convert attribute ids to attribute names
            let newCheckpointCriteria = []
            for (const attributeId of Object.keys(checkpoint.checkpointCriteria)) {
                newCheckpointCriteria[attributes[attributeId].attributeName] = checkpoint.checkpointCriteria[attributeId]
            }
            setCheckpointCriteria(newCheckpointCriteria)

        } // eslint-disable-next-line
    }, [checkpoint])

    return (
        <Card>
            <Card.Header>
                <Accordion.Toggle as={Button} variant="link" style={{fontSize:"14px"}} eventKey={index} onClick={() => handleActiveCheckpointChange(index)}>
                    Checkpoint {index}
                </Accordion.Toggle>
                <Link id={"viewCheckpointLink" + index} to={`/checkpoints/${checkpointId}?mode=view`}>
                    <Tooltip title={<p className="h6 mb-0">View Checkpoint</p>}>
                        <Icon  path={mdiEye} size={1.25}/>
                    </Tooltip>
                </Link>

                {(user.devAdmin || checkpoint?.checkpointOwner === user.userAlias ||
                    process?.processAdmins?.includes(user.userAlias)) ?
                    <Link id={"editCheckpointLink" + index} className="ps-2" to={`/checkpoints/${checkpointId}?mode=edit`}>
                        <Tooltip title={<p className="h6 mb-0">Edit Checkpoint</p>}>
                            <Icon path={mdiPencil} size={1.25}/>
                        </Tooltip>
                    </Link>

                    :
                    null}
            </Card.Header>
            <Accordion.Collapse eventKey={index.toString()}>
                <Card.Body className="overflow-scroll mediumCard">
                    <Table
                        columnDefinitions={[
                            {
                                id: "field",
                                header: "Field name",
                                cell: e => e.field,
                                width: 150,
                                minWidth: 140
                            },
                            {
                                id: "value",
                                header: "Field value",
                                cell: e => e.value,
                                width: 150,
                                minWidth: 140
                            }
                        ]}
                        items={tableValues}

                        loadingText="Loading checkpoint"
                    />
                </Card.Body>
            </Accordion.Collapse>
        </Card>
    )
}