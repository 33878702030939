import React, {useEffect, useState} from 'react';
import {Button, Col, Container, Row} from "react-bootstrap";
import {FormField, Input, Tabs} from "@amzn/awsui-components-react";
import {Amplify, API} from "aws-amplify";
import {Link} from "react-router-dom";
import CheckpointView from "./tabs/CheckpointView";
import {CircularProgress} from "@material-ui/core";
import "../assets/css/components/MyKeysCheckpointsView.css";
import {withStyles} from "@material-ui/core/styles";
import Tooltip from "@material-ui/core/Tooltip";

/**
 * The My Keys and Checkpoints View in the application
 * @param props properties needed to render the page
 * - user: the user who is logged in
 * - process: the process being currently referenced
 * - responsibilityAttributeId: needed by CheckpointCard component, responsibility attribute id
 * @returns {JSX.Element}
 * @constructor
 */
export default function MyKeysCheckpointsView(props) {
    const user = props.user;
    const process = props.process;
    const attributes = props.attributes

    // monitors selected tab
    const [selectedTabId, setSelectedTabId] = useState('owned-checkpoints');

    const [associatedCheckpoints, setAssociatedCheckpoints] = useState([]);
    const [associatedCheckpointsOwned, setAssociatedCheckpointsOwned] = useState([]);

    // selection fields related to alias
    const [pageAlias, setPageAlias] = useState(user.userAlias);
    const [aliasSelectValue, setAliasSelectValue] = useState(pageAlias);
    const [aliasError, setAliasError] = useState("");

    // state variable to track loading screen while loading associations
    const [loadingAssociations, setLoadingAssociations] = useState(true);

    // track which checkpoint tab mouse is hovering over, if any at all
    const [hoveringOnTabID, setHoveringOnTabID] = useState(null);

    // method that updates all the state variables when page alias and process id are changed
    const updateAllUserAssociations = (alias) => {

        // read user associations and update the corresponding state variables with entities
        if (alias != null) {
            API.get("ApproverMatrixAPI", `/checkpoints/associations/${alias.toLowerCase()}?processId=${process.processId}`)
                .then(response => {
                    setAssociatedCheckpointsOwned(response["checkpointOwnerForCheckpoints"]);
                    setAssociatedCheckpoints(response["associatedCheckpoints"]);
                    setLoadingAssociations(false);
                }).catch(err=>{
                setLoadingAssociations(false);
            })
        }
    }
    const resetAllUserAssociations = () => {
        setAssociatedCheckpointsOwned([]);
        setAssociatedCheckpoints([]);
    }

    // function that pulls associated keys and checkpoints when this page is loaded
    // these dependencies are the only ones we want to trigger update
    useEffect(() => {
        updateAllUserAssociations(pageAlias); // eslint-disable-next-line
    }, [process])


    // method that is called when the alias is changed, fetching a new user
    const updateAlias = () => {
        if (aliasSelectValue.toLowerCase() !== pageAlias.toLowerCase()) {
            setLoadingAssociations(true);
        } else {
            setLoadingAssociations(false);
        }
        Amplify.API.get("ApproverMatrixAPI", `/users/${aliasSelectValue.toLowerCase()}`).then(
            response => {
                setAliasSelectValue(response.userAlias);
                setAliasError("");
                setPageAlias(response.userAlias);
                updateAllUserAssociations(response.userAlias);
            })
            .catch(err => {
                setLoadingAssociations(false);
                resetAllUserAssociations();
                setAliasError(`Unable to find alias ${aliasSelectValue} in system.`);
            });
    }

    // style for checkpoint button tooltips
    const FormattedTooltip = withStyles({
        arrow: {
            color: "black",
        },
        tooltip: {
            fontSize: "11.5px",
            maxWidth: 400,
            textAlign: "center",
            backgroundColor: "black",
        },
    })(Tooltip);

    // style for checkpoint tab tooltips
    const useTooltipStyles = {
        tooltip: {
            // place tooltip directly underneath each tab instead of underneath parent container
            margin: (props) => props.tab === "Checkpoints Owned" ?
                "-40px 200px 0px 0px" : "-40px 0px 0px 200px",
            fontSize: "11.5px",
            maxWidth: 400,
            textAlign: "center",
            backgroundColor: "black"
        },
        arrow: {
            color: "black",
            left: "200px !important"
        }
    };
    const CheckpointTabTooltip = withStyles(useTooltipStyles)(Tooltip);
    // hoveringOnTabID if not null matches to one of these keys to get tooltip content
    const checkpointTabTooltipValues = new Map([
        ['Checkpoints Owned', 'Clicking on Checkpoints Owned will show the previously saved checkpoints.'],
        ['Checkpoints Assigned', 'Clicking on Checkpoints Assigned will show the upcoming milestones where the ' +
        'user is an Assignee (Approver or Delegate).'],
    ]);

    return (
        <Container className="pt-4">
            <Row className="my-4 justify-content-md-center">
                <Col lg={2}>
                    <FormField
                        label="User Alias">
                    </FormField>
                    <Input
                        type="text"
                        id="userAliasInput"
                        onChange={({detail}) => setAliasSelectValue(detail.value)}
                        value={aliasSelectValue}/>
                    <FormField
                        errorText={aliasError}>
                    </FormField>
                </Col>
                <Col lg={1}>
                    <Button className="search-button" id="searchButton" color="primary" size="lg" onClick={() => updateAlias()}>Search</Button>
                </Col>
            </Row>
            <Row className="justify-content-md-center mb-5">
                <Col lg={1}></Col>
                <Col lg={6}>
                    <Link to="/checkpoints?mode=create">
                        <FormattedTooltip arrow title="To be used by PDP meeting organizers to generate a list of Assignees (Approver, Consulted, Informed) and Delegates (1 & 2) for PDP milestone reviews. Default selection: Approver, Consulted and Delegate 1.">
                            <Button variant="success" id="createCheckpointButton" className="m-3" size="lg">Create Checkpoint</Button>
                        </FormattedTooltip>
                    </Link>
                    {(user.devAdmin || process.processAdmins.includes(user.userAlias)) ?
                        <Link to="/key">
                            <Button variant="success" id="createKeyButton" className="m-3" size="lg">Create Keys</Button>
                        </Link>
                    : undefined}
                    <Link to="/bulk-edit-keys">
                        <FormattedTooltip arrow title="To be used by Assignees (Approvers, Consulted, Informed) and Delegates to maintain a list of names for their respective functions across different Product Lines, Milestones and Hardware Leaders.">
                            <Button variant="success" id="manageResponsibilityButton" className="m-3" size="lg">Manage Responsibility Matrix</Button>
                        </FormattedTooltip>
                    </Link>
                </Col>
            </Row>

            {loadingAssociations ?
                <Row className="justify-content-md-center">
                    <Col lg={4} className="mt-5 d-fls justify-content-center">
                        <h2 className="mt-1">Loading your checkpoints...</h2>
                        <CircularProgress className="ms-3 mb-1" size={30}/>
                    </Col>
                </Row> :
                <React.Fragment>
                    <Row className="justify-content-md-center">
                        <Col lg={4} className="d-flex justify-content-center">
                            <CheckpointTabTooltip
                                                  tab={hoveringOnTabID}
                                                  arrow
                                                  title={hoveringOnTabID === null ?
                                                  '':checkpointTabTooltipValues.get(hoveringOnTabID)}
                            >
                                <div
                                    // determine content of tooltip based on the tab mouse is hovering over
                                    onMouseOver={(e) => {
                                            // update state if mouse is directly over text, not just in parent container
                                            e.target.childElementCount === 0 ?
                                                setHoveringOnTabID(e.target.textContent):setHoveringOnTabID(null);
                                    }}
                                >
                                    <Tabs
                                        activeTabId={selectedTabId}
                                        onChange={(Checkpoint) => {
                                            setSelectedTabId(Checkpoint.detail.activeTabId);
                                        }}
                                        tabs={[
                                            {
                                                label: "Checkpoints Owned",
                                                id: "owned-checkpoints",
                                            },
                                            {
                                                label: "Checkpoints Assigned",
                                                id: "assigned-checkpoints",
                                            }
                                        ]}
                                    />
                                </div>
                            </CheckpointTabTooltip>
                        </Col>
                    </Row>
                {selectedTabId === "owned-checkpoints" ?
                    <CheckpointView associatedCheckpoints={associatedCheckpointsOwned}
                                    user={user}
                                    pageAlias={pageAlias}
                                    process={process}
                                    attributes={attributes}
                                    isOwned={true} />
                    : null}
                {selectedTabId === "assigned-checkpoints" ?
                    <CheckpointView associatedCheckpoints={associatedCheckpoints}
                                    user={user}
                                    pageAlias={pageAlias}
                                    process={process}
                                    attributes={attributes}
                                    isOwned={false} />
                    : null}
                </React.Fragment>
            }
        </Container>
    );
}
