import { Button, Checkbox } from "@amzn/awsui-components-react";
import Tooltip from '@material-ui/core/Tooltip';
import { withStyles } from "@material-ui/core/styles";
import { Amplify } from "aws-amplify";
import React, { useEffect, useRef, useState } from "react";
import { Col, Row } from "react-bootstrap";
import { CopyToClipboard } from "react-copy-to-clipboard/lib/Component";
import { CSVLink } from "react-csv";
import { ACTIVITY_TYPE_ATTR_VALUES_UPDATE } from "../common/Constants";

/**
 * Method responsible for all reporting including export to csv and export to email list
 * @param props properties needed to render this component
 * - overrideItems: list of all overrides for this checkpoint
 * - tableVals: list of table values being displayed
 * - overridesToMap: method to convert overrides to more readable format
 * - delegateRank: max rank of delegate to include in table
 * - maxDelegateRank: if delegateRank is any the maximum number of delegates
 * - responsibilityAttributeId: used to get list of Responsibility field values
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckpointReport(props) {
    const overrideItems = props.overrideItems;
    const tableVals = props.tableVals;
    const overridesToList = props.overridesToList;
    const [delegateRank,setDelegateRank] = useState({label: "1st Rank", value: 1});
    const maxDelegateRank = props.maxDelegateRank;
    const responsibilityAttributeId = props.responsibilityAttributeId;
    const roleAttributeId =props.roleAttributeId;
    const rankMap = props.rankMap;
    const checkpointDate = props.checkpointDate;
    const programName = props.programName;
    const selectedValues = props.selectedValues;
    const attributes = props.attributes;
    const roleSortOrderMap= props.roleSortOrderMap;
    const user = props.user;
    const updateMessage = props.updateMessage;
    const CSVExportLinkRef = useRef(null);

    // state variable for checkboxes
    const [checkedMap, setCheckedMap] = useState({})

    // methods for data for CSV export and copy to clipboard components
    const [tableExport, setTableExport] = useState([]);
    const [emailCopyList, setEmailCopyList] = useState('');
    const [aliasCopyList, setAliasCopyList] = useState('');
    const APPROVER_TEXT="Approver (A)";
    const CONSULTED_TEXT="Consulted (C)";
    const PROGRAM_NAME_TEXT= "Program Name";
    const DATE_TEXT= "Date";

    const ROLE_TEXT = "Role";
    const NOT_AVAILABLE_TEXT = "Not Available";
    const NOT_APPLICABLE_TEXT = "Not Applicable";
    const DUMMY_VALUES = new Set([NOT_APPLICABLE_TEXT,NOT_AVAILABLE_TEXT]);

    const NOT_AVAILABLE_EMAIL = "Not Available@amazon.com";
    const NOT_APPLICABLE_EMAIL = "Not Applicable@amazon.com";

    const attributeIDNameMap = {}
    const attributeNameIdMap = {}
    Object.values(attributes).forEach(attribute =>  attributeIDNameMap[attribute.attributeId] = attribute.attributeName);
    Object.values(attributes).forEach(attribute =>  attributeNameIdMap[attribute.attributeName] = attribute.attributeId);

    useEffect(() => {
        let newCheckedMap = {}
        responsibilityAttributeId?.values?.forEach(val => {
            newCheckedMap[val] = false
            if(val === APPROVER_TEXT || val === CONSULTED_TEXT){
                newCheckedMap[val] = true
            }
        })
        setCheckedMap(newCheckedMap)
    }, [responsibilityAttributeId])

    const setResponsibilityMap = (responsibilityValue, checked) => {
        let newCheckedMap = {...checkedMap}
        newCheckedMap[responsibilityValue] = checked
        setCheckedMap(newCheckedMap)
    }

    let metadata = [
              {"Label":PROGRAM_NAME_TEXT, "Value":`"${programName}"`},
              {"Label":DATE_TEXT, "Value":`"${checkpointDate}"`}
    ]
    Object.keys(attributeIDNameMap).forEach(key=>{
        if(key!== responsibilityAttributeId.id && key !==roleAttributeId.id){
          metadata.push({"Label":attributeIDNameMap[key], "Value":`"${selectedValues[key]!==undefined? selectedValues[key]:""}"`})
        }
    })

    // method to convert table data to CSV exportable data
    const tableToCsv = (tableData) => {
        let entries = tableData?.map(entry => {
            let tableEntry = {"Role": `${entry.role}`, "Responsibility": `${entry.responsibility}`}
            if (!entry.assigneeAliasDeleted && entry.assigneeAlias) {
                tableEntry["Assignee Name"] = `${entry.assigneeName}`
            }
            else{
                tableEntry["Assignee Name"] = ""
            }

            let delegateRankThreshold = delegateRank.value === 2 ? maxDelegateRank : delegateRank.value;
            for (let i = 1; i <= delegateRankThreshold; i++) {
                if (`delegate${i}Alias` in entry) {
                    if (!entry[`delegate${i}AliasDeleted`]) {
                        const delegateName = entry[`delegate${i}Name`]
                        tableEntry[`Delegate ${i} Name`] = `${delegateName}`
                    }
                    else{
                        tableEntry[`Delegate ${i} Name`] = ""
                    }
                }
                else{
                    tableEntry[`Delegate ${i} Name`] = ""
                }
            }

            if (!entry.assigneeAliasDeleted && entry.assigneeAlias && !DUMMY_VALUES.has(entry.assigneeAlias)) {
                tableEntry["Assignee Email"] = `${entry.assigneeAlias}@amazon.com`
            }
            else{
                tableEntry["Assignee Email"] = ""
            }

            for (let i = 1; i <= delegateRankThreshold; i++) {
                if (`delegate${i}Alias` in entry) {
                    if (!entry[`delegate${i}AliasDeleted`]) {
                        const delegateAlias = entry[`delegate${i}Alias`]
                        if(!DUMMY_VALUES.has(delegateAlias)){
                             tableEntry[`Delegate ${i} Email`] = `${delegateAlias}@amazon.com`
                        }
                        else{
                            tableEntry[`Delegate ${i} Email`] = ""
                        }
                    }
                    else{
                        tableEntry[`Delegate ${i} Email`] = ""
                    }
                }
                else{
                    tableEntry[`Delegate ${i} Email`] = ""
                }
            }
            return tableEntry
        });
        return entries
    };

    // method to convert table data to email list
    const tableToEmail = (tableData) => {
        let aliases = tableData?.flatMap(entry => {
            const res = Object.keys(entry).filter(key => key.endsWith("Alias") && !entry[`${key}Deleted`])
                .map(key => entry[key])
            return res
        })

        const emails = [...new Set(aliases)]
        return emails
    }

    // Method that takes an override object and converts it to table rows
    const overrideToTableRows = (overrides) => {
        return overrides.map(override => {
            let tableEntry = {
                active: true, role: override.role, responsibility: override.responsibility,
                assigneeAlias: override.assignee, assigneeAliasDeleted: false
            }
            for (let i = 0; i < override.delegates.length; i++) {
                tableEntry[`delegate${i + 1}Alias`] = override.delegates[i]
                tableEntry[`delegate${i + 1}AliasDeleted`] = false
            }
            return (tableEntry)
        })
    }

    // method that refreshes table export whenever checkbox is updated
    // overrides are now added to the table
    const refreshTableExport = () => {
        let tableRows = [];
        const overridesList = overridesToList(overrideItems);
        responsibilityAttributeId?.values?.forEach(value => {
            if (checkedMap[value]) {
                const currOverrides = overridesList.filter(item => item.responsibility === value)

                tableRows.push.apply(tableRows, tableToCsv(tableVals[value]))
                tableRows.push.apply(tableRows, tableToCsv(overrideToTableRows(currOverrides)))
            }
        });
        const sortedTableRows = tableRows.sort(function (a, b) {
            //Adding these to trim /" character added at front and end of the strings.
            let aText = a[ROLE_TEXT].trim();
            let bText = b[ROLE_TEXT].trim();
            // return roleSortOrderMap[aText] - roleSortOrderMap[bText];

            if (roleSortOrderMap[aText] <  roleSortOrderMap[bText]) { return -1; }
            if (roleSortOrderMap[aText] >  roleSortOrderMap[bText]) { return 1; }
            return 0;
        });
        setTableExport(sortedTableRows)
    };

    // method that refreshes email list whenever overrides are updated or checkbox is ticked
    const refreshEmailList = () => {
        let emails = []
        const overridesList = overridesToList(overrideItems);
        responsibilityAttributeId?.values?.forEach(value => {
            if (checkedMap[value]) {
                const currOverrides = overridesList.filter(item => item.responsibility === value)
                emails.push.apply(emails, currOverrides.map(item => item.assignee))
                emails.push.apply(emails, currOverrides.flatMap(item => item.delegates))
                emails.push.apply(emails, tableToEmail(tableVals[value]))
            }
        })
        console.log(checkedMap, emails);
        setAliasCopyList(emails);
        const emailAddresses = emails.map(alias => `${alias}@amazon.com`)
        if (!(emailAddresses.length === 0)) {
            let emailSet = new Set(emailAddresses);
            if(emailSet.has(NOT_AVAILABLE_EMAIL))
                emailSet.delete(NOT_AVAILABLE_EMAIL)

            if(emailSet.has(NOT_APPLICABLE_EMAIL))
                emailSet.delete(NOT_APPLICABLE_EMAIL)
            setEmailCopyList([...emailSet].join(';'))
        }
        else {
            setEmailCopyList("No matching assignees or delegates")
        }
    }

    const disableButtons = () => {
        return !Object.values(checkedMap).includes(true);
    }

    // effect hook that refreshes these components (export and email list copy)
    // only want it to execute on UI components, functions are constants so no need to add to dependency list
    useEffect(() => {
        refreshTableExport()
        refreshEmailList() // eslint-disable-next-line
    }, [maxDelegateRank,checkedMap, overrideItems, tableVals,delegateRank])

    const validateBackgroundActivityAndAttributes = ()=>{
        let attributeList = Object.values(attributes);
        attributeList.forEach(attr => {attr.alias = user.userAlias});
        Amplify.API.post("ApproverMatrixAPI", "/background-activity", {
            body: {
                processId: props.processId,
                activityType: ACTIVITY_TYPE_ATTR_VALUES_UPDATE,
                attributeValidation: true,
                attributes: attributeList
            }
        })
        .then(response => {
            if (response.existUnfinishedActivity || !response.attributeValuesMatched){
                updateMessage("Unable to export because there are admins editing this process! Please come back later and refresh page", "error");
            }else{
                CSVExportLinkRef.current.link.click();
            }
        })
        .catch(
            err => {
                updateMessage("Unable to export because we cannot validate background activity and attribute values!", "error");
            }
        )
    }

    // style for checkpoint button tooltips
    const FormattedTooltip = withStyles({
        tooltip: {
            fontSize: "13px",
            maxWidth: 400,
            textAlign: "center",
            backgroundColor: "black"
        },
        arrow: {
            color: "black"
        }
    })(Tooltip);

    return (
        <React.Fragment>
            <Row className=" mt-4 mb-1 justify-content-md-center">
                <h3 className="d-flex justify-content-center">
                    <b>Please note: Roles listed as Approver and Consulted should always be invited to PDP meetings. Roles listed as Informed do not need to be invited to meetings, however, they should be copied on all documents and meeting notes.</b>

                </h3>
            </Row>
            <Row className="pt-5 pb-2 justify-content-md-center">
                <Col lg={4} className="d-flex justify-content-center">
                    <h3>Responsibility Matrix Selection</h3>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col lg={6} className="d-flex justify-content-center">
                    {responsibilityAttributeId?.values?.map((value,index) => (<Checkbox id = {"responsibilityCheckpoint-" + index} className="pe-4"
                        onChange={({ detail }) =>
                            setResponsibilityMap(value, detail.checked)
                        }
                        checked={checkedMap[value]}>
                        {value}
                    </Checkbox>))}
                </Col>
            </Row>


            <Row className="pt-5 pb-2 justify-content-md-center">
                <Col lg={4} className="d-flex justify-content-center">
                    <h3>Delegate Rank</h3>
                </Col>
            </Row>
            <Row className="justify-content-md-center">
                <Col lg={1}>
                    <Checkbox
                        id="rankFirstCheckbox"
                        onChange={() => setDelegateRank(rankMap[1])}
                        checked={delegateRank.value === 1}>
                        1st Rank
                    </Checkbox>
                </Col>
                <Col lg={1}>
                    <Checkbox
                        id="allRankCheckbox"
                        onChange={() => setDelegateRank(rankMap[2])}
                        checked={delegateRank.value === 2}>
                        All Ranks
                    </Checkbox>
                </Col>
            </Row>
            <div className="checkpointReport-action-buttons-wrapper">
                <div className="checkpointReport-action-buttons-copy-alias">
                    <CopyToClipboard text={aliasCopyList}>
                            <FormattedTooltip interactive
                                            arrow
                                            title={<p>Click here to copy the list of aliases of Assignees and Delegates. This list can be
                                                used to create ANT group which can then be used in Workdocs folders to provide
                                                document access. Link to creating ANT group: <a href="https://permissions.amazon.com/add_group.mhtml">
                                                    https://permissions.amazon.com/add_group.mhtml</a></p>}>
                                <div id="copyAliasToClipboardButton">
                                    <Button className="blue-button-theme-enabled-state" disabled={disableButtons()}>
                                        Copy Alias List to Clipboard
                                    </Button>
                                </div>
                            </FormattedTooltip>
                        </CopyToClipboard>
                </div>
                <div className="checkpointReport-action-buttons-copy-email">
                    <CopyToClipboard arrow text={emailCopyList}>
                            <FormattedTooltip title="Click here to copy the email addresses of Assignees and Delegates to paste in the Outlook calendar invite for PDP milestone reviews.">
                            <div id="copyEmailToClipboardButton">
                                <Button className="blue-button-theme-enabled-state" disabled={disableButtons()}>
                                    Copy Email List to Clipboard
                                </Button>
                            </div>
                            </FormattedTooltip>
                        </CopyToClipboard>
                </div>
                <div className="checkpointReport-action-buttons-csv">
                    <FormattedTooltip arrow interactive
                                        title="Click here to generate a table of required functions and their associated
                                        Assignees and Delegates. This table can be used for approval emails or added to
                                        PDP documents when necessary. Steps to format the downloaded file: a) Select all
                                        from top left of spreadsheet b) Click on 'Format' from toolbar and select
                                        'AutoFit Column Width'.">
                            {/* <Button id="exportAsCSVButton" className="checkpoint-button m-2" variant="success" disabled={disableButtons()}>
                                <CsvDownload filename="raci-table" datas={tableExport}>
                                    Export as CSV
                                </CsvDownload>
                            </Button> */}
                            <div id="exportAsCSVButton">
                                <Button className="blue-button-theme-enabled-state" 
                                    disabled={disableButtons()}
                                    onClick={validateBackgroundActivityAndAttributes}
                                >
                                    Export as CSV
                                </Button>
                            </div>
                        </FormattedTooltip>
                </div>
                {/* hidden component */}
                <CSVLink filename="raci-table" data={tableExport} className="hidden" ref={CSVExportLinkRef}/>
            </div>
        </React.Fragment>
)

}