import React, {useState} from "react";
import {Accordion, Col, Row} from "react-bootstrap";
import CheckpointCard from "../cards/CheckpointCard";
import {Pagination} from "@amzn/awsui-components-react";

/**
 * Method that handles the checkpoint tab
 * @param props
 * - associatedCheckpoints: list of checkpoints to display
 * - isOwned: whether displaying owned tab or not
 * - user: the user and their information
 * - pageAlias: the input selection
 * - attributes: list of all process attributes
 * - responsibilityAttributeId: the attribute id for responsibility
 * @returns {JSX.Element}
 * @constructor
 */
export default function CheckpointView(props) {
    const associatedCheckpoints = props.associatedCheckpoints
    const isOwned = props.isOwned
    const user = props.user
    const pageAlias = props.pageAlias
    const attributes = props.attributes
    const process = props.process

    const [activeCheckpoint, setActiveCheckpoint] = useState('0')
    const [page, setPage] = useState(1)
    const rowsPerPage = 10

    // slice list being displayed based on page selected
    const displayArr = [...new Set([...associatedCheckpoints])]
    const indexMap = displayArr.reduce(function(map, val, index) {
        map[val] = index;
        return map;
    }, {});

    const currView = displayArr.slice((page-1)*rowsPerPage, page*rowsPerPage)

    // method to handle change to selected element in list
    const handleActiveCheckpointChange = (newActiveCheckpoint) => {
        if (activeCheckpoint === newActiveCheckpoint) {
            //allows us to close expanded item by clicking its toggle while open
            setActiveCheckpoint('-1')
        }
        else {
            setActiveCheckpoint(newActiveCheckpoint)
        }
    }


    return (
        <React.Fragment>
            <Row className="justify-content-md-center mb-5">
                <Col lg={3} className="d-flex justify-content-md-center">
                    <Pagination
                        ariaLabels={{
                            nextPageLabel: "Next page",
                            previousPageLabel: "Previous page",
                            pageLabel: pageNumber =>
                                `Page ${pageNumber} of all pages`
                        }}
                        currentPageIndex={page}
                        onChange={({ detail }) =>{
                            setPage(detail.currentPageIndex)
                        }}
                        pagesCount={Math.ceil(displayArr.length / rowsPerPage)}
                    />
                </Col>
            </Row>
            {currView.length === 0 ? <Row className="justify-content-md-center mb-5">
                <Col lg={2} className="d-flex justify-content-md-center">
                    <h4>No checkpoints found...</h4>
                </Col>
            </Row> : null}
            <Row className="justify-content-md-center mb-5">
                <Col lg={8}>
                    <Accordion activeKey={activeCheckpoint}>

                        {currView.map((checkpointId) => {
                            return (<CheckpointCard pageAlias={pageAlias} index={indexMap[checkpointId].toString()} user={user}
                                                    process={process}
                                                    isOwned={isOwned}
                                                    attributes={attributes}
                                                    activeCheckpoint={activeCheckpoint}
                                                    handleActiveCheckpointChange={handleActiveCheckpointChange}
                                                    checkpointId={checkpointId}/>)
                        })}
                    </Accordion>
                </Col>
            </Row>
        </React.Fragment>
    )
}